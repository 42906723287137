import { Component, Input, NgZone, OnInit } from '@angular/core'
import { MethodsService } from '../../../../services/methods/methods.service'
import { PIInstancesService } from '../../../../services/api/services/pi-instances.service'
import { GlobalState } from '../../../../app.state'
import { PiEventConsoleService } from '../../../../services/api/services/pi-event-console.service'
import { NgbTabChangeEvent } from '@ng-bootstrap/ng-bootstrap'
import { TableWithFilters } from '../../../absracts/table-with-filters'
import { SpinnerService } from '../../../../services/spinner/spinner.service'
import { LoggerService } from '../../../../services/logger/logger.service'

declare const enum EventGroupsImpactedScope {
  pages = 'pages',
  beacons = 'beacons',
  countries = 'countries',
  devices = 'devices',
}

type ScopeTabKey = `${EventGroupsImpactedScope}-tab`

@Component({
  selector: 'event-group-impacted',
  templateUrl: './event-group-impacted.component.html',
  styleUrls: ['./event-group-impacted.component.scss'],
})
export class EventGroupImpactedComponent extends TableWithFilters implements OnInit {

  @Input() tenantId: string
  @Input() eventGroupId: string
  @Input() currentTime: { text: string, absTime: Function, time: Function }
  @Input() metadata: ExtendedEventGroupSchema
  @Input() beacons: AffectedBeaconsResult
  @Input() pages: { count: number, pages: SingleEventGroupPage[] } = { count: 0, pages: [] }
  @Input() countries: EventGroupCountriesResult
  @Input() browsers: EventGroupBrowsersResult
  @Input() rarelySeenPages: boolean = false

  //self sorting
  currentCountries: CountryItem[] = []
  currentBrowsers: BrowserDeviceItem[] = []

  highestBeaconPoint: number = 0

  tableHeaders: TableHeader[]
  pagesTableHeaders: TableHeader[]
  beaconsTableHeaders: TableHeader[]
  countriesTableHeaders: TableHeader[]
  devicesTableHeaders: TableHeader[]
  readonly title = ''

  currentSortBy: string = 'impactedBeacons'
  paginationName = 'Pages'

  //state
  currentScope: EventGroupsImpactedScope = EventGroupsImpactedScope.pages
  activeScope: ScopeTabKey = 'pages-tab'
  _isRefreshing: boolean = false

  //imports
  integerWithCommas = MethodsService.integerWithCommas
  numberToSuffix = MethodsService.numberToSuffix
  copyToClipboard = MethodsService.copyToClipboard
  truncateURL = MethodsService.truncateURL
  open = window.open

  constructor (private _api: PiEventConsoleService, private _clusters: PIInstancesService, private _zone: NgZone, private _state: GlobalState) {
    super()
    this.pagesTableHeaders = [
      //@formatter:off
      { width: '75',  name: 'Page URL',           sortable: true,     field: 'pageUrl'            },
      { width: '',    name: 'Fields',             sortable: false,    field: 'severity'           },
      { width: '',    name: 'Scripts',            sortable: true,     field: 'numOfScripts'       },
      { width: '',    name: 'Beacons',            sortable: true,     field: 'impactedBeacons'    },
      { width: '',    name: '',                   sortable: false,    field: ''                   },
      //@formatter:on
    ].filter(x => !!x)
    this.beaconsTableHeaders = [
      //@formatter:off
      { width: '',    name: 'Time',               sortable: true,     field: 'timestamp'          },
      { width: '',    name: 'User IP',            sortable: false,    field: ''                   },
      { width: '',    name: 'Country / Area',     sortable: false,    field: ''                   },
      { width: '',    name: 'Device',             sortable: false,    field: ''                   },
      { width: '',    name: 'Page',               sortable: false,    field: 'url'                },
      //@formatter:on
    ].filter(x => !!x)
    this.countriesTableHeaders = [
      //@formatter:off
      { width: '',    name: 'Country / Area',     sortable: true,     field: '_country'             },
      { width: '',    name: 'Beacons',            sortable: true,     field: 'impactedBeacons'      },
      { width: '',    name: 'Percentage',         sortable: true,     field: 'impactedBeaconsRatio' },
      { width: '50',  name: '',                   sortable: false,    field: ''                     },
      //@formatter:on
    ].filter(x => !!x)
    this.devicesTableHeaders = [
      //@formatter:off
      { width: '',    name: 'Device',             sortable: true,     field: 'browser'                    },
      { width: '',    name: 'Beacons',            sortable: true,     field: 'impactedBeacons'      },
      { width: '',    name: 'Percentage',         sortable: true,     field: 'impactedBeaconsRatio' },
      { width: '50',    name: '',                 sortable: false,    field: ''                     },
      //@formatter:on
    ].filter(x => !!x)

    this.tableHeaders = this.pagesTableHeaders
  }

  ngOnInit (): void {
    this.highestBeaconPoint = Math.max(...this.beacons.timeSeries.map(beacon => beacon.beacons))
    this.numOfAvailableDocs = this.pages.count
    this.setCountries()
    this.setDevices()
  }

  onTabChange ($event: NgbTabChangeEvent) {
    const tab = $event.nextId as ScopeTabKey
    const scope = tab.split('-')[0] as EventGroupsImpactedScope
    this.currentScope = scope
    this.activeScope = tab
    switch (scope) {
      case EventGroupsImpactedScope.pages:
        this.numOfAvailableDocs = this.pages.count
        this.tableHeaders = this.pagesTableHeaders
        this.currentSortBy = 'impactedBeacons'
        this.paginationName = 'Pages'
        break
      case EventGroupsImpactedScope.beacons:
        this.numOfAvailableDocs = this.beacons.recentBeaconsCount
        this.tableHeaders = this.beaconsTableHeaders
        this.currentSortBy = 'timestamp'
        this.paginationName = 'Beacons'
        break
      case EventGroupsImpactedScope.countries:
        this.numOfAvailableDocs = this.countries.count
        this.tableHeaders = this.countriesTableHeaders
        this.currentSortBy = 'impactedBeacons'
        this.paginationName = 'Countries'
        break
      case EventGroupsImpactedScope.devices:
        this.numOfAvailableDocs = this.browsers.count
        this.tableHeaders = this.devicesTableHeaders
        this.currentSortBy = 'impactedBeacons'
        this.paginationName = 'Devices and Browsers'
        break
    }
    this.isSortReversed = false
    this.currentPage = 1
  }

  async refreshTable (background: boolean = false): Promise<void> {
    if (this._isRefreshing) {
      return
    }
    this._isRefreshing = true
    try {

      switch (this.currentScope) {
        case EventGroupsImpactedScope.pages: {
          SpinnerService.spin('mini')
          this.pages = await this._api.getEventGroupPages(this.tenantId, this.eventGroupId, {
            fromDate: this.currentTime.time(),
            toDate: Date.now(),
            limit: this.currentItemsPerPage,
            page: this.currentPage - 1,
            sortBy: this.currentSortBy,
            sortDirection: this.isSortReversed ? 1 : -1,
            ...(this.rarelySeenPages ? { includeRarelySeenPages: true } : {})
          })
          break
        }
        case EventGroupsImpactedScope.beacons: {
          SpinnerService.spin('mini')
          this.beacons = (await this._api.getEventGroupBeacons(this.tenantId, this.eventGroupId, {
            fromDate: this.currentTime.time(),
            toDate: Date.now(),
            limit: this.currentItemsPerPage,
            page: this.currentPage - 1,
            sortBy: this.currentSortBy,
            sortDirection: this.isSortReversed ? 1 : -1,
          })).beacons
          break
        }
        case EventGroupsImpactedScope.countries: {
          this.setCountries()
          break
        }
        case EventGroupsImpactedScope.devices: {
          this.setDevices()
          break
        }
      }

      // await this.fetchData()
    } catch (e) {
      MethodsService.toast('error', 'Error fetching event groups', e.toString(), 8)
      LoggerService.error(e)
    } finally {
      SpinnerService.stop('mini')
      this._zone.run(() => {})
      this._isRefreshing = false
    }
  }

  setCountries () {
    this.currentCountries = this.countries.countries
      .sort((a, b) => (this.isSortReversed ? 1 : -1) * (typeof a[this.currentSortBy] == 'number' ? (a[this.currentSortBy] - b[this.currentSortBy]) : a[this.currentSortBy].localeCompare(b[this.currentSortBy])))
      .slice((this.currentPage - 1) * this.currentItemsPerPage, this.currentPage * this.currentItemsPerPage)
  }

  setDevices () {
    this.currentBrowsers = this.browsers.browsers
      .sort((a, b) => (this.isSortReversed ? 1 : -1) * (typeof a[this.currentSortBy] == 'number' ? (a[this.currentSortBy] - b[this.currentSortBy]) : a[this.currentSortBy].localeCompare(b[this.currentSortBy])))
      .slice((this.currentPage - 1) * this.currentItemsPerPage, this.currentPage * this.currentItemsPerPage)
  }

}
