import { Injectable } from '@angular/core'
import { ApiService } from '../../../shared/absracts/api-service'
import { UserDataService } from '../../user-data/user-data.service'
import { MethodsService } from '../../methods/methods.service'
import { GlobalState } from '../../../app.state'
import { PiEventConsoleProcessorService } from '../processors/pi-event-console-processor.service'

@Injectable()
export class PiEventConsoleService extends ApiService {

  constructor (protected _state: GlobalState, protected _user: UserDataService) {
    super(_state, _user)
  }

  async getEventGroups (customerId: string, options: EventGroupsFeedFilterArguments): Promise<CountedEventGroups> {
    const queryString = MethodsService.objectToQueryString({ ...options, customerId })
    const res = await this.get<CountedResultObject<CountedEventGroups>>(`api/admin_api/event_console/event_groups?${queryString}`)
    return {
      count: res.data.data.count,
      eventGroups: PiEventConsoleProcessorService.processEventGroup(res.data.data.eventGroups)
    }
  }

  async getEventGroupMetadata (customerId: string, eventGroupId: string, options: EventGroupsFeedFilterArguments) {
    const queryString = MethodsService.objectToQueryString({ ...options, eventGroupId, customerId })
    const res = await this.get<CountedResultObject<ExtendedEventGroupSchema>>(`api/admin_api/event_console/event_group?${queryString}`)
    return {
      metadata: PiEventConsoleProcessorService.processEventGroupMetadata(res.data.eventGroup)
    }
  }

  async getEventGroupPages (customerId: string, eventGroupId: string, options: EventGroupsFeedFilterArguments) {
    const queryString = MethodsService.objectToQueryString({ ...options, eventGroupId, customerId })
    const res = await this.get<CountedResultObject<EventGroupPagesAllPagesResult>>(`api/admin_api/event_console/event_group/pages?${queryString}`)
    return {
      count: res.data.data.count,
      pages: PiEventConsoleProcessorService.processEventGroupPages(res.data.data.pages)
    }
  }

  async getEventGroupBeacons (customerId: string, eventGroupId: string, options: EventGroupsFeedFilterArguments) {
    const queryString = MethodsService.objectToQueryString({ ...options, eventGroupId, customerId })
    const res = await this.get<CountedResultObject<AffectedBeaconsResult>>(`api/admin_api/event_console/event_group/beacons?${queryString}`)
    return {
      beacons: PiEventConsoleProcessorService.processEventGroupBeacons(res.data.data)
    }
  }

  async getEventGroupScripts (customerId: string, eventGroupId: string, options: EventGroupsFeedFilterArguments) {
    const queryString = MethodsService.objectToQueryString({ ...options, eventGroupId, customerId })
    const res = await this.get<CountedResultObject<CountedEventGroupScripts>>(`api/admin_api/event_console/event_group/scripts?${queryString}`)
    return {
      count: res.data.data.count,
      scripts: PiEventConsoleProcessorService.processEventGroupScripts(res.data.data.eventGroupScripts)
    }
  }

  async getScriptDestinations (customerId: string, eventGroupId: string, options: EventGroupAggregatedEndpointFilters) {
    const queryString = MethodsService.objectToQueryString({ ...options, eventGroupId, customerId })
    const res = await this.get<CountedResultObject<EventGroupScriptEndpointsResult>>(`api/admin_api/event_console/event_group/destination_urls?${queryString}`)
    return {
      count: res.data.data.count,
      endpoints: PiEventConsoleProcessorService.processEventGroupScriptDestinations(res.data.data.endpoints)
    }
  }

  async getEventGroupCountries (customerId: string, eventGroupId: string, options: EventGroupsFeedFilterArguments) {
    const queryString = MethodsService.objectToQueryString({ ...options, eventGroupId, customerId })
    const res = await this.get<CountedResultObject<EventGroupCountriesResult>>(`api/admin_api/event_console/event_group/countries?${queryString}`)
    return {
          countries: PiEventConsoleProcessorService.processEventGroupCountries(res.data.data.countries),
          count: res.data.data.count,
          customerId: res.data.data.customerId
        }
  }

  async getEventGroupBrowsers (customerId: string, eventGroupId: string, options: EventGroupsFeedFilterArguments) {
    const queryString = MethodsService.objectToQueryString({ ...options, eventGroupId, customerId })
    const res = await this.get<CountedResultObject<EventGroupBrowsersResult>>(`api/admin_api/event_console/event_group/browsers?${queryString}`)
    return {
      _uniqueDevices: PiEventConsoleProcessorService.processEventGroupBrowsersUniqueDevices(res.data.data),
      browsers: PiEventConsoleProcessorService.processEventGroupBrowsers(res.data.data.browsers),
      count: res.data.data.count,
      customerId: res.data.data.customerId
    }
  }

  // async syncIndexes (clusters: string[]): Promise<ResultObject<Record<string, boolean>>> {
  //   const body = JSON.stringify({ clusters })
  //   const response = await this.post(`api/admin_api/mongodb_analytics/sync_indexes`, body, true, 'application/json')
  //   return response.data
  // }
  //
  // async getCollections (options: FilterArguments<CollectionStatsFilters>): Promise<MongoDBAnalyticsCollectionsResponse> {
  //   const queryString = MethodsService.objectToQueryString(options)
  //   const res = await this.get<MongoDBAnalyticsCollectionsResponse>(`api/admin_api/mongodb_analytics/collections?${queryString}`)
  //   return {
  //     filterOptions: res.data.filterOptions,
  //     count: res.data.count,
  //     data: PiMongoDBAnalyticsProcessorService.processCollections(res.data.data)
  //   }
  // }
  //
  // async getCollectionsIndexes (options: FilterArguments<CollectionIndexStatsFilters>): Promise<MongoDBAnalyticsCollectionsIndexesResponse> {
  //   const queryString = MethodsService.objectToQueryString(options)
  //   const res = await this.get<MongoDBAnalyticsCollectionsIndexesResponse>(`api/admin_api/mongodb_analytics/collections_indexes?${queryString}`)
  //   return {
  //     filterOptions: res.data.filterOptions,
  //     count: res.data.count,
  //     data: PiMongoDBAnalyticsProcessorService.processCollectionsIndexes(res.data.data)
  //   }
  // }

}

